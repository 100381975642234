@import "tachyons";
@import "~csshake/scss/csshake";

.shake-hand-slow {
  @include do-shake('shake-hand-slow', 20, 0, 0, 2.5s, .1, $opacity: false);
}

.shake-hand-upper-slow {
  @include do-shake('shake-hand-slow-2', 18, 0, 0, 3.5s, .1, $opacity: false);
}

.fade-in {
  animation: fadeIn ease 20s;
  -webkit-animation: fadeIn ease 20s;
  -moz-animation: fadeIn ease 20s;
  -o-animation: fadeIn ease 20s;
  -ms-animation: fadeIn ease 20s;
}

.fade-in2 {
  animation: fadeIn ease 5s;
  -webkit-animation: fadeIn ease 5s;
  -moz-animation: fadeIn ease 5s;
  -o-animation: fadeIn ease 5s;
  -ms-animation: fadeIn ease 5s;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

html, body, #root, .container {
  @extend .h-100;
}

body {
  @extend .ma0, .pa0, .f5, .sans-serif, .bg-black, .white;
}

.text-shadow { text-shadow: 1px 0px 8px rgba(0, 0, 0, 0.55) };

a {
  @extend .white, .no-underline;
}

.container {
  @extend .center, .w-100, .mw8-ns, .pa3, .flex, .flex-column, .content-stretch;

  max-width: 1280px;

  .main {
    flex-grow: 1;
  }
}

#header {
  .menu {
    a {
      @extend .pl3;
    }
  }
}

.home {
  @extend .relative, .flex, .items-center, .content-center;

  z-index: 2;

  &--inner {
    @extend .w-100, .fade-in;

    h1, h2 {
      @extend .text-shadow;
    }
  }

  &--article {
    @extend .relative, .mt5;

    z-index: 2;

    .resp-video {
      @extend .fade-in2;
    }
  }

  &--container {
    @extend .relative;

    z-index: 1;

    .hero-image {
      img {
        @extend .center, .db;

        &.upper-hand {
          @extend .shake-hand-upper-slow, .shake-constant;
        }
      }

      &.lower {
        @extend .nt5, .shake-hand-slow, .shake-constant;
      }
    }
  }
}

.resp-video {
  @extend .db, .aspect-ratio--16x9, .aspect-ratio;

  z-index: 1;

  video {
    @extend .db, .aspect-ratio--object;
  }
}

.copyright {
  @extend .tc, .pv2, .silver;
}

#footer {
  @extend .relative;

  z-index: 2;
}